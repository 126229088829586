import { memo } from 'react';
import { InternalLinkMemo } from '../../internal-link/internal-link';
import { BulletDiamondIcon } from '../../svg-icons/bullet-diamond';
import styles from './card-list-header.module.css';
import { AnchorButton } from '../../buttons/button/button';

export type CardListHeaderProps = {
  title: string;
  id?: string;
  className?: string;
  titleLink?: string;
  icon?: React.ReactNode;
  size?: 'medium' | 'large';
  buttonLink?: string;
  buttonLinkText?: string;
};

export function CardListHeader({
  id,
  title,
  titleLink,
  className = '',
  icon = <BulletDiamondIcon />,
  size = 'medium',
  buttonLink,
  buttonLinkText,
}: CardListHeaderProps) {
  return (
    <h2 id={id} className={`${styles.root} ${styles[`${size}Size`] || ''} ${className}`}>
      {icon}
      {titleLink ? (
        <InternalLinkMemo className={styles.title} href={titleLink}>
          {title}
        </InternalLinkMemo>
      ) : (
        <span className={styles.title}>{title}</span>
      )}
      {buttonLink && buttonLinkText && (
        <AnchorButton className={styles.button} href={buttonLink} theme="SecondaryDark">
          {buttonLinkText}
        </AnchorButton>
      )}
    </h2>
  );
}

export const CardListHeaderMemo = memo(CardListHeader);
